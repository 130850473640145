import React, { useEffect, useState } from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ListRender } from '@commons/list-render/ListRender';

import * as styles from './privacy-policy-content.module.scss';
import { Link } from '@commons/link/Link';

const listOfServices = [
  'Hubspot',
  [
    'Hubspot service is provided by HubSpot, Inc., HubSpot Ireland Limited, and their affiliates.',
    'We use Hubspot to collect and manage your personal information and requests through our website. We may use it to send personalized emails, but also for real-time data analytics and insights to track and personalize our marketing activities.',
    'For more information on the privacy practices of Hubspot, visit their Privacy Policy: https://legal.hubspot.com/privacy-policy.',
  ],
  'Survicate',
  [
    'Survicate service is provided by Survicate Sp. z o. o.',
    'We use Survicate to collect and manage feedback we get through the surveys. We may use it to send personalized emails, but also for data analytics and insights to track and personalize our marketing activities.',
    'For more information on the privacy practices of Survicate visit their Privacy Policy: https://help.survicate.com/en/articles/3943207-terms-of-service-privacy-policy-gdpr-and-dpa.',
  ],
];

const listOfRemaketingServices = [
  'Facebook remarketing service:',
  [
    'Facebook remarketing service is provided by Meta Inc. ',
    'You can learn more about interest-based advertising from Facebook by visiting this page: https://www.facebook.com/help/164968693837950. ',
    'To opt out of Facebook`s interest-based ads, follow these instructions from Facebook: https://www.facebook.com/help/568137493302217. ',
    'Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt out of Facebook and other participating companies through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt out using your mobile device settings. ',
    'For more information on the privacy practices of Facebook, please visit Facebook`s Data Policy: https://www.facebook.com/privacy/explanation',
  ],
  'Twitter remarketing services.',
  [
    'Twitter remarketing service is provided by Twitter Inc.',
    'You can opt-out from Twitter’s interest-based ads by following their instructions: https://support.twitter.com/articles/20170405. ',
    'You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: https://twitter.com/privacy.',
  ],
  'LinkedIn remarketing services.',
  [
    'LinkedIn remarketing service is provided by LinkedIn Corporation.',
    'You can opt-out from LinkedIn’s interest-based ads by following their instructions: https://www.linkedin.com/psettings/guest-controls   ',
    'You can learn more about the privacy practices and policies of LinkedIn by visiting their Privacy Policy page: https://www.linkedin.com/legal/privacy-policy ',
  ],
  'Google remarketing service.',
  [
    'Google Ads remarketing service is provided by Google Inc. ',
    'You can opt out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: http://www.google.com/settings/ads ',
    'Google also recommends installing the Google Analytics Opt-out Browser Add-on: https://tools.google.com/dlpage/gaoptout, for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.',
    'For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: http://www.google.com/intl/en/policies/privacy ',
  ],
  'Hubspot',
  [
    'Hubspot service is provided by HubSpot, Inc., HubSpot Ireland Limited, and their affiliates.',
    'We use Hubspot for analytical purposes on our website, to optimize it and offer you the best possible and user-friendly service.',
    'Hubspot uses cookies, which are saved on your computer and allow us to analyze how you use our website. The information gathered (e.g. IP address, geographic location, type of browser, duration of the visit and pages viewed) is evaluated by Hubspot on our behalf so that we can generate reports on the visit and the pages viewed and can therefore improve our online presence.',
    'If you don’t want Hubspot to record your activities on our website, you can deny it in the cookie message that pops up on your first visit to our website.',
    'For more information on the privacy practices of Hubspot, visit their privacy policy: https://legal.hubspot.com/privacy-policy.',
  ],
  'Youtube services',
  [
    'Our website embeds videos of the website YouTube. The website operator is Google Ireland Limited.',
    'Further information on data protection can be found in the provider`s data protection declaration https://policies.google.com/privacy.',
  ],
  'Microsoft Advertising',
  [
    'Microsoft Advertising is provided by Microsoft Corporation.',
    'Microsoft Advertising uses web code to track and understand conversion paths. Additionally, we may use cookies and similar technologies to collect information about your browsing behavior and preferences. This information may be used to serve you targeted advertising through Microsoft Ads.',
    'Microsoft Advertising can collect cookies to build remarketing audiences for analytical or advertising purposes.',
    'All data collection is anonymous.',
    'For more information on the privacy practices of Microsoft, visit their privacy policy: https://about.ads.microsoft.com/en-us/policies/legal-privacy-and-security.',
  ],
];

const listOfGoogleAnalitics = [
  'Google Analytics ',
  [
    'Google Analytics service is provided by Google Inc.  ',
    'You can opt out of Google Analytics service and prevent it from using your information by installing the Google Analytics Opt-out Browser tool: https://tools.google.com/dlpage/gaoptout.  ',
    'For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://www.google.com/policies/privacy.',
  ],
];

const listOfHotjar = [
  'HotJar',
  [
    'HotJar service is provided by Hotjar Ltd.',
    'We use Hotjar in order to better understand our users’ needs and to optimize this website and experience. Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. ',
    "Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices (in particular device's IP address (captured and stored only in anonymized form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), preferred language used to display our website). Hotjar stores this information in a pseudonymized user profile. Neither Hotjar nor we will ever use this information to identify individual users or to match it with further data on an individual user. ",
    'For more information on the privacy practices of HotJar, please visit the HotJar web page: https://www.hotjar.com/legal/policies/privacy. ',
  ],
];
const listOfMatomo = [
  'Matomo',
  [
    'Matomo is an open-source web analytics platform. We use it to measure, collect and analyze visitor data for purposes of understanding and optimizing our website.',
    'We use Matomo to analyze the behavior of website visitors to identify potential pitfalls; e.g. not-found pages, search engine indexing issues, and which content is most appreciated. Once the data is processed (number of visitors reaching a not-found page, viewing only one page), Matomo generates reports to take action, for example, changing the layout of the pages, publishing fresh content, etc.',
    'The information collected includes the user data such as cookies, visitor’s IP address, location, browser type, screen resolution, etc. and the data on the visit on the website such as pages visited, pages generation time etc. ',
    'For more information on the privacy practices of Matomo, please visit the Matomo web page: https://matomo.org/privacy-policy/.',
  ],
];
const listOfPersonalInformationList = [
  'We may share personal information for legal, protection, and safety purposes.',
  'We may share information to protect the rights and property of the Company, our agents, customers, and others. This includes enforcing our agreements, policies, and terms of use.',
  'We may also share aggregated and/or anonymized data with our trusted vendors, marketing agencies, app and social media platform providers for our use only.',
  'We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.',
];

const listOfDataStoragePeriod = [
  'laws which may obligate CodiLime to store data for a specific period of time, ',
  'period which is required to protect the Data Controller’s interests,',
  'period required by CodiLime to provide services,',
  'period for which the consent has been granted,',
  'period until such time that consent is withdrawn.',
];

const listOfDataProcessingList = [
  'transparent information, communication and modalities for the exercise of the rights of the data subject - you can ask us for information concerning your personal data.',
  'the right of access the data subject - you can access your personal data.',
  'the right to rectification - you can rectify (correct, supplement) your personal data.',
  'the right to erasure ("the right to be forgotten") - we will delete your data, if you so request.',
  'the right to restriction of processing - we will limit the processing of your personal data, if you so request.',
  'notification obligation regarding the rectification or erasure of personal data or restriction of processing - we will inform you of any rectification or erasure of your personal data or restriction of processing carried out according to the GDPR.',
  'the right to data portability - if you so request and if technically possible, we can send your personal data directly to you or to another Controller.',
  'the right to object - you can object to the processing of your personal data for specific purposes, if you so request.',
  'automated individual decision-making, including profiling - if you do not want to be subject to a decision based solely on automated processing, including profiling, such request should be made. ',
  'the right to withdraw consent at any time without affecting the lawfulness of the processing which was carried out on the basis of consent before its withdrawal.',
];

export const PrivacyPolicyContent = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent(document.querySelector('#ot-sdk-cookie-policy')?.outerHTML || '');
  }, []);

  return (
    <StandardSection id="privacy-policy" title="Data Controller">
      <div className={styles.privacyPolicy}>
        <p>
          CodiLime Sp. z o. o., headquartered at Grzybowska 5A, postal code 00-132, Warsaw, Poland, further referred to
          as “the Company” or “CodiLime” is a data controller under European Union Data Protection Legislation. This
          privacy policy (“Policy”) describes how CodiLime and its related companies (“Company”) collect, use and share
          the personal information of consumer users of this website (the “Site”) pursuant to the Regulation (EU)
          2016/679 of the European Parliament. This Policy also applies to any of our other websites that post this
          Policy. This Policy does not apply to websites that post different statements. We never collect or process
          information without the consent of users.
        </p>
        <h3>Information you give us</h3>
        <p>
          When you contact us we collect your first name, last name, email address, phone number, job position, company
          name and any other information you give us directly on our site.
        </p>
        <p>
          We may use third-party services which collect, monitor and analyze this type of information, for communication
          purposes in the forms such as website chats, personalized emails and newsletters.
        </p>
        <ListRender withArrows blue data={listOfServices} />
        <h3>Information automatically collected</h3>
        <p>
          We automatically log information about you and your computer. For example, when visiting our site, we log your
          computer operating system type, browser type, browser language, the website you visited before browsing our
          site, pages you viewed, how long you spent on a page, access times, Internet protocol (IP) address and
          information about your use of and actions on our site.
        </p>
        <p>
          Cookies.
          <br />
          We may log information using “cookies.” Cookies are small data files stored on your hard drive by a website.
          Cookies help us make our site and your visit better. We use cookies to see which parts of our Site people use
          and like and to count visits to our site.
        </p>
        <p>
          Web beacons.
          <br />
          We may log information using digital images called web beacons on our Site or in our emails. We use web
          beacons to manage cookies, count visits, and to learn which marketing activities work and which do not. We
          also use web beacons to tell if you open or act on our emails.
        </p>
        <p>
          IP Addresses.
          <br />
          When you visit CodiLime websites, the Company collects your Internet Protocol (&quot;IP&quot;) addresses to
          track and aggregate non-personal information. For example, CodiLime uses IP addresses to monitor the regions
          from which Customers and Visitors navigate the Company’s websites.
        </p>
        <p>
          Third Party Cookies.
          <br />
          From time-to-time, CodiLime may engage third parties, like Facebook or Google, to track and analyze
          statistical information usage and volume from individuals who visit the Company’s websites. CodiLime may also
          use other third-party cookies to track the performance of Company advertisements. CodiLime may also contract
          with third-party advertising networks that collect IP addresses and other website navigation information on
          the Company’s websites and emails and on third-party websites. Ad networks follow your online activities over
          time by collecting website navigation information through automated means, including through the use of
          cookies. They use this information to provide advertisements about products and services tailored to your
          interests. You may see these advertisements on other websites. This process also helps us manage and track the
          effectiveness of our marketing efforts.
        </p>
        <p>
          These third parties may use cookies to help us analyze how our users are using the website and they have their
          own Privacy Policies addressing how they use such information.
        </p>
        <ListRender withArrows blue data={listOfRemaketingServices} />
        <p>
          In addition, we may use third-party services, such as Google Analytics, Hotjar and Matomo, which collect,
          monitor and analyze this type of information in order to increase our website&apos;s functionality.
        </p>
        <ListRender withArrows blue data={listOfGoogleAnalitics} />
        <ListRender withArrows blue data={listOfHotjar} />
        <ListRender withArrows blue data={listOfMatomo} />
        <p>
          This site is protected by reCAPTCHA and Google. For more information please see Google Privacy Policy
          https://policies.google.com/privacy and Terms of Service https://policies.google.com/terms.
        </p>
        <h3>Use of personal information</h3>
        <p>
          The Company uses your Personal Information for the communication purposes related to services offered by
          CodiLime or to answer requests. For example, if you fill out a &quot;Contact us&quot; web form or a web form
          to download any of the documents available on our website, the Company will use the information provided to
          contact you about your interest in our services.
        </p>
        <p>
          The Company may also use your Personal Information for marketing purposes. For example, the Company may use
          information you provide to contact you to further discuss your interest in the Services and to send you our
          newsletter or information regarding the Company and its partners.
        </p>
        <p>
          CodiLime uses automatically collected information to operate and improve the Company’s websites. For more
          details see Cookie Policy.
        </p>
        <h3>Sharing of personal information</h3>
        <p>
          We only use your personal information for business purposes. However, we may share personal information as
          follows:
        </p>
        <ListRender withArrows blue data={listOfPersonalInformationList} />
        <h3>Information choices and changes</h3>
        <p>
          Our marketing emails tell you how to “opt out.” Should you opt out of receiving them, we may still send you
          non-marketing emails. Non-marketing emails include emails about your accounts and our business dealings with
          you. You can request we change contact choices and update your personal information. You may withdraw your
          consent at any time. To do so, contact us at our address: gdpr@codilime.com. Implementation of the above
          changes could take us up to 72 hours so please be aware that you could still get some unwanted communication
          within this period. You can typically remove and reject cookies from our site with your browser settings. Many
          browsers are set to accept cookies until you change your settings. If you remove or reject our cookies, it
          could affect how our Site works for you. For more details see Cookie Policy.
        </p>
        <h3>The security of your personal information </h3>
        <p>
          We protect personal information. All information provided to us is stored on secure servers and guarded by
          strict procedures and well-trained staff.
        </p>
        <h3>Personal data storage period </h3>
        <p>
          Your personal data storage period will depend on the purpose of their processing. Your personal data storage
          period is calculated based on the following criteria:
        </p>
        <ListRender withArrows blue data={listOfDataStoragePeriod} />
        <h3>Your rights relating to data processing</h3>
        <p>
          Our Privacy Policy complies with requirements of the GENERAL DATA PROTECTION REGULATION (GDPR), which
          guarantees you the following rights:
        </p>
        <ListRender withArrows blue data={listOfDataProcessingList} />
        <p>
          In the case of any doubts as to the correctness of your personal data processing by CodiLime, you have the
          right to file a complaint with the President of the Office for Personal Data Protection. Upon request CodiLime
          will provide you with information about whether we hold any of your personal information. You may access,
          correct, or request deletion of your personal information by contacting us at gdpr@codilime.com. We will
          respond to your request within 1 month. That period may be extended by two further months where necessary,
          taking into account the complexity and number of requests. We will inform you of any such extension within one
          month of receipt of the request, together with the reasons for the delay. In certain circumstances we may be
          required by law to retain your personal information, or may need to retain your personal information in order
          to continue providing a service.
        </p>
        <h3>Contact information</h3>
        <p>
          We welcome your comments or questions about this privacy policy. You may contact us at our address:
          gdpr@codilime.com.
        </p>
        <h3>Changes to this privacy policy</h3>
        <p>
          We reserve the right to change this privacy policy. If we make any changes, we will change the Last Updated
          date below.
        </p>
        <p>This privacy policy was last updated on July 16, 2024.</p>
      </div>
      <h2 id="cookie-policy" className={styles.cookiePolicy}>
        <Link href="#cookie-policy" aria-label="Cookie Policy" className={styles.anchor}>
          <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16">
            <path
              fillRule="evenodd"
              d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            />
          </svg>
        </Link>
        Cookie Policy
      </h2>
      {/* <!-- OneTrust Cookies List start --> */}
      <div className={styles.cookieList} dangerouslySetInnerHTML={{ __html: content }} />
      {/* <!-- OneTrust Cookies List end --> */}
    </StandardSection>
  );
};
