import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { Header } from '@commons/header/Header';

import { PrivacyPolicyContent } from '@pages-impl/privacy-policy';

export default function Index(props) {
  return (
    <Layout {...props} oldPage>
      <SEO
        title="Privacy Policy - CodiLime"
        description="We value your privacy. Read our privacy policy to see how we protect it."
        noIndex={true}
      />
      <Header title="Privacy Policy" id="privacy-policy-header" src="bg.jpg" className="terms-of-service" />
      <PrivacyPolicyContent />
    </Layout>
  );
}
